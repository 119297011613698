// @ts-ignore
import * as React from "react";
import { FC, ReactNode } from "react";
import * as classNames from "classnames";
import classes from "./paper.module.css";

interface IPaper {
  children: ReactNode;
  className: string;
}

export const Paper: FC<IPaper> = ({ children, className }) => (
  <div className={classNames(classes.paper, className)}>
    <div className={classes.paperShadowBefore}> </div>
    <div className={classes.reset}>{children}</div>
    <div className={classes.paperShadowAfter}> </div>
  </div>
);
