import * as React from "react";
import { FC } from "react";
import classes from "./portrait.module.css";

interface IPortrait {
  src: string;
  year: string;
  alt: string;
}
export const Portrait: FC<IPortrait> = ({ src, year, alt }) => {
  return (
    <figure className={classes.figure}>
      <figcaption>{year}</figcaption>
      <img src={src} width="240" height="318" alt={alt} />
    </figure>
  );
};
