import * as React from "react";
import { FC } from "react";
import { ILocalizedPage } from "../common/types/page";
import { useLocalized } from "../common/localization/localize";
import { Paper } from "../common/components/canvas/paper";
import { Stations } from "../components/cv/stations";
import parchmentClasses from "../common/css/backgrounds.module.css";
import classes from "../components/cv/cv.module.css";
import { Portrait } from "../components/cv/portrait";

export const CvEntryPoint: FC<ILocalizedPage> = ({ lang: _lang }) => {
  const stations = useLocalized("cv_stations");
  const portraits = [
    {
      src: "/cv/portrait_1959.jpg",
      alt: "Norber Jan Paprotny, Portrait 1959",
      year: "1959"
    },
    {
      src: "/cv/portrait_1990.jpg",
      alt: "Norber Jan Paprotny, Portrait 1990",
      year: "1990"
    },
    {
      src: "/cv/portrait_2008.jpg",
      alt: "Norber Jan Paprotny, Portrait 2008",
      year: "2008"
    }
  ];
  return (
    <Paper className={parchmentClasses.parchment}>
      <div className={classes.container}>
        <div className={classes.portraits}>
          {portraits.map(portrait => (
            <Portrait {...portrait} />
          ))}
        </div>
        <Stations stations={stations} />
      </div>
    </Paper>
  );
};
