import * as React from "react";
import { FC } from "react";
import classes from "./stations.module.css";

interface IStationsProps {
  stations: string[];
}

export const Stations: FC<IStationsProps> = ({ stations }) => (
  <div className={classes.container}>
    {stations.map((station, index) => (
      <p key={`station${index}`} className={classes.paragraph}>
        {station}
      </p>
    ))}
  </div>
);
